<template>
  <q-table :rows="serverRows" :columns="serverColumns" row-key="id" selection="multiple" v-model:selected="selected"
    :loading="loading" v-model:pagination="serverTablePagination" @request="onRequest" grid flat bordered>
    <template v-slot:top>
      <q-btn color="primary" :disable="loading" label="新增" @click="openDialog = true; form.id = ''; isEdit = false" />
      <q-btn v-if="serverRows.length !== 0" class="q-ml-sm" color="primary" :disable="loading" label="删除"
        @click="removeRow" />
      <q-space />
    </template>
    <template v-slot:item="props">
      <div class="q-pa-xs col-xs-12 col-sm-6 col-md-4 col-lg-3"
        :style="props.selected ? 'transform: scale(0.95);' : ''">
        <q-card :class="props.selected ? 'bg-grey-2' : ''">
          <q-card-section>
            <q-checkbox dense v-model="props.selected" :label="`${props.row.serverIp}:${props.row.serverPort}`" />
          </q-card-section>
          <q-separator />
          <q-list dense>
            <q-item v-for="col in props.cols.filter(col => col.name !== 'desc')" :key="col.id">
              <q-item-section>
                <q-item-label>{{ col.label }}</q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-item-label caption>{{ col.value }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
          <q-card-actions align="right">
            <q-btn flat label="编辑" color="primary" @click="editRow(props.row)" />
          </q-card-actions>
        </q-card>
      </div>
    </template>
  </q-table>
  <q-dialog v-model="openDialog" persistent @hide="onReset">
    <q-card style="min-width: 300px">
      <q-card-section>
        <div class="text-h6">{{ isEdit ? '编辑服务器信息' : '添加服务器' }}</div>
      </q-card-section>
      <q-card-section>
        <q-form @submit.prevent="onSubmit" @reset="onReset">
          <q-input v-if="false" filled v-model="form.id" label="ID" hint="请输入服务器ID" disabled />
          <q-input filled v-model="form.serverIp" label="IP 地址" hint="请输入服务器IP地址" lazy-rules
            :rules="[val => !!val || 'IP 地址是必填项']" />
          <q-input filled v-model="form.serverPort" label="端口" hint="请输入服务器端口" lazy-rules
            :rules="[val => !!val || '端口是必填项']" />
          <q-input filled v-model="form.serverAuth" label="认证码" hint="请输入服务器认证码" lazy-rules
            :rules="[val => !!val || '认证码是必填项']" />
          <q-input filled v-model="form.description" label="描述" hint="请输入服务器描述" />
          <q-select filled v-model="form.source" :options="sourceOptions" label="来源" hint="请选择服务器来源" map-options lazy-rules
            :rules="[val => !!val || '来源是必填项']" />
          <q-toggle v-model="form.state" label="状态" checked-icon="check_circle" unchecked-icon="cancel" :default="true" color="green" />
          <q-card-actions align="right">
            <q-btn flat label="取消" color="primary" v-close-popup />
            <q-btn flat label="提交" color="primary" type="submit" />
          </q-card-actions>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, onMounted } from 'vue';
import { queryServerList, updateServer, addServer, deleteServer } from "@/api/server";

const serverColumns = [
  { name: 'id', required: true, label: 'ID', align: 'left', field: row => row.id, sortable: true },
  { name: 'source', required: true, label: '来源', align: 'left', field: row => row.source, format: val => val === 0 ? '官方' : '三方' },
  { name: 'serverIp', required: true, label: 'IP', align: 'center', field: row => row.serverIp },
  { name: 'serverPort', required: true, label: '端口', align: 'center', field: row => row.serverPort },
  { name: 'serverAuth', required: true, label: '认证码', align: 'center', field: row => row.serverAuth },
  { name: 'description', required: true, label: '描述', align: 'left', field: row => row.description },
  { name: 'state', required: true, label: '状态', align: 'center', field: row => row.state, format: val => val ? '正常' : '停用' }
]

export default {
  name: 'ServerList',
  setup() {
    const loading = ref(false)
    const serverRows = ref([])
    const selected = ref([])
    const serverTablePagination = ref({
      sortBy: 'id',
      descending: false,
      page: 1,
      rowsPerPage: 10,
      rowsNumber: 0
    })
    const openDialog = ref(false);
    const form = ref({
      id: '',
      serverIp: '',
      serverPort: '',
      serverAuth: '',
      description: '',
      source: null,
      state: true
    });
    const sourceOptions = [
      { label: '官方', value: 0 },
      { label: '三方', value: 1 }
    ];
    const isEdit = ref(false);

    function onSubmit() {
      const processedForm = {
        ...form.value,
        source: form.value.source.value,
        state: form.value.state === true ? 1 : 0
      };
      
      if (processedForm.serverIp && processedForm.serverPort && processedForm.serverAuth && processedForm.source !== null) {
        if (isEdit.value) {
          // 更新服务器
          updateServer(processedForm).then((res) => {
            openDialog.value = false;
            onRequest({
              pagination: serverTablePagination.value
            });
          }).catch((error) => {
            console.error('Error updating server:', error);
            alert('更新服务器失败，请检查输入并重试');
          });
        } else {
          // 新增服务器
          addServer(processedForm).then((res) => {
            openDialog.value = false;
            onRequest({
              pagination: serverTablePagination.value
            });
          }).catch((error) => {
            console.error('Error adding server:', error);
            alert('添加服务器失败，请检查输入并重试');
          });
        }
      } else {
        alert('请填写所有必填项');
      }
    }


    function onReset() {
      form.value = {
        id: '',
        serverIp: '',
        serverPort: '',
        serverAuth: '',
        description: '',
        source: null,
        state: true
      };
    }

    function onRequest(props) {
      const { page, rowsPerPage, sortBy, descending } = props.pagination
      loading.value = true
      queryServerList(page, rowsPerPage).then((res) => {
        serverTablePagination.value.rowsNumber = res.data.total
        serverRows.value.splice(0, serverRows.value.length, ...res.data.extend)
        serverTablePagination.value.page = page
        serverTablePagination.value.rowsPerPage = rowsPerPage
        serverTablePagination.value.sortBy = sortBy
        serverTablePagination.value.descending = descending
        loading.value = false
      }).catch((error) => {
        console.error('Error fetching server list:', error);
        loading.value = false;
      });
    }

    function editRow(row) {
      form.value = { ...row };
      form.value.state = row.state === 1;
      isEdit.value = true;
      openDialog.value = true;
    }

    function removeRow() {
      if (selected.value.length === 0) {
        alert('请选择要删除的服务器');
        return;
      }

      if (confirm('确定要删除选中的服务器吗？')) {
        loading.value = true;
        const ids = selected.value.map(row => row.id);

        deleteServer(ids).then((res) => {
          onRequest({
            pagination: serverTablePagination.value
          });
        }).catch((error) => {
          console.error('Error deleting servers:', error);
          alert('删除服务器失败，请重试');
        }).finally(() => {
          loading.value = false;
        });
      }
    }

    onMounted(() => {
      onRequest({
        pagination: serverTablePagination.value
      })
    })

    return {
      openDialog,
      form,
      sourceOptions,
      onSubmit,
      onReset,
      loading,
      tab: ref('serverListTab'),
      splitterModel: ref(20),
      serverTablePagination,
      serverColumns,
      serverRows,
      onRequest,
      selected,
      removeRow,
      editRow,
      isEdit
    };
  }
};
</script>
