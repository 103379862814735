<template>
  <q-page>
    <q-table
      :rows="reportList"
      :columns="columns"
      row-key="id"
      @request="fetchReports"
      :filter="selectedStatus"
      v-model:pagination="serverTablePagination"
    >
      <template v-slot:body-cell-status="props">
        <q-td :props="props" :class="getStatusClass(props.row.status)">
          {{ formatStatus(props.row.status) }}
        </q-td>
      </template>
      <template v-slot:body-cell-reporter="props">
        <q-td :props="props">
          {{ props.row.reporter.personaName }}
        </q-td>
      </template>
      <template v-slot:body-cell-reported="props">
        <q-td :props="props">
          {{ props.row.reported.personaName }}
        </q-td>
      </template>
      <template v-slot:body-cell-processor="props">
        <q-td :props="props">
          <span v-if="props.row.status !== 1">{{
            props.row.processor.personaName
          }}</span>
        </q-td>
      </template>
      <template v-slot:body-cell-audit="props">
        <q-td>
          <q-btn
            v-if="props.row.status === 1"
            @click="openAuditDialog(props.row)"
            label="审核"
            color="primary"
          />
        </q-td>
      </template>
      <template v-slot:body-cell-gameId="props">
        <q-td :props="props" class="text-primary cursor-pointer">
          <q-tooltip> 点击可下载对局demo </q-tooltip>
          <span
            @click="downloadGameDemo(props.row.gameId)"
            style="cursor: pointer"
          >
            {{ props.row.gameId }}
          </span>
        </q-td>
      </template>

      <template v-slot:top-right>
        <q-select
          v-model="selectedStatus"
          :options="statusOptions"
          label="筛选状态"
          map-options
        />
      </template>
    </q-table>
    <q-dialog v-model="dialog" @hide="closeDialog">
      <q-card>
        <q-form>
          <q-card-section>
            <div class="text-h6">审核举报</div>
            <q-input
              filled
              v-model="auditOpinion"
              label="审核意见"
              type="textarea"
              lazy-rules
              :rules="[(val) => !!val || '审核意见是必填项']"
            />
          </q-card-section>
          <q-card-actions>
            <q-btn
              label="同意"
              color="positive"
              type="submit"
              @click="submitAudit(true)"
            />
            <q-btn
              label="驳回"
              color="negative"
              type="submit"
              @click="submitAudit(false)"
            />
            <q-btn label="取消" v-close-popup />
          </q-card-actions>
        </q-form>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import {
  queryReportList,
  auditReport,
  downloadGameDemoFile,
} from "@/api/report"; // 假设接口在这个路径
import { ref, onMounted } from "vue";
import { useQuasar, date } from "quasar";

export default {
  setup() {
    const $q = useQuasar();
    const reportList = ref([]);
    const statusOptions = [
      { label: "全部", value: 0 },
      { label: "待处理", value: 1 },
      { label: "已通过", value: 2 },
      { label: "已驳回", value: 3 },
    ];
    const selectedStatus = ref(statusOptions[1]); // 默认选中状态1
    const columns = [
      // 隐藏ID列
      { name: "source", label: "来源", align: "left", field: "source" },
      { name: "gameId", label: "对局ID", align: "left", field: "gameId" },
      {
        name: "reporter",
        label: "举报人",
        align: "left",
        field: "reporter.personaName",
      },
      {
        name: "reported",
        label: "被举报人",
        align: "left",
        field: "reported.personaName",
      },
      {
        name: "reporterIp",
        label: "举报人IP",
        align: "left",
        field: "reporterIp",
      },
      {
        name: "reportedIp",
        label: "被举报人IP",
        align: "left",
        field: "reportedIp",
      },
      { name: "reason", label: "举报理由", align: "left", field: "reason" },
      {
        name: "status",
        label: "状态",
        align: "left",
        field: "status",
        // 使用自定义格式化函数
        format: (val) => val,
      },
      {
        name: "auditOpinion",
        label: "审核意见",
        align: "left",
        field: "auditOpinion",
      },
      {
        name: "createTime",
        label: "举报时间",
        align: "left",
        field: "createTime",
        format: (val) => date.formatDate(val, "YYYY-MM-DD HH:mm:ss"),
      },
      {
        name: "updateTime",
        label: "审核时间",
        align: "left",
        field: "updateTime",
        format: (val) => date.formatDate(val, "YYYY-MM-DD HH:mm:ss"),
      },
      {
        name: "processor",
        label: "审核人",
        align: "left",
        field: "processor.personaName",
      },
      { name: "audit", label: "审核", align: "center" },
    ];
    const dialog = ref(false);
    const auditOpinion = ref("");
    const currentReportId = ref(null);
    const serverTablePagination = ref({
      sortBy: "id",
      descending: false,
      page: 1,
      rowsPerPage: 10,
      rowsNumber: 0,
    });

    const fetchReports = (props) => {
      const { page, rowsPerPage, sortBy, descending } = props.pagination;

      queryReportList(selectedStatus.value.value, page, rowsPerPage)
        .then((response) => {
          serverTablePagination.value.rowsNumber = response.data.extend.total;
          reportList.value.splice(
            0,
            reportList.value.length,
            ...response.data.extend.records
          );
          serverTablePagination.value.page = page;
          serverTablePagination.value.rowsPerPage = rowsPerPage;
          serverTablePagination.value.sortBy = sortBy;
          serverTablePagination.value.descending = descending;
        })
        .catch((error) => {
          console.error("获取举报列表失败:", error);
        });
    };

    const openAuditDialog = (report) => {
      currentReportId.value = report.id;
      dialog.value = true;
    };

    const submitAudit = async (agree) => {
      if (!auditOpinion.value) {
        $q.notify({
          message: "请填写审核意见",
          color: "negative",
        });
        return;
      }

      try {
        await auditReport(currentReportId.value, agree, auditOpinion.value);
        $q.notify({
          message: "审核成功",
          color: "positive",
        });
        closeDialog();
        fetchReports();
      } catch (error) {
        $q.notify({
          message: "审核失败",
          color: "negative",
        });
      }
    };

    const closeDialog = () => {
      dialog.value = false;
      auditOpinion.value = "";
    };

    const downloadGameDemo = (gameId) => {
      // 调用下载接口
      downloadGameDemoFile(gameId)
        .then((response) => {
          // 从响应头中提取文件名
          const contentDisposition = response.headers["content-disposition"];
          let filename = "gameDemos.zip"; // 默认文件名

          if (
            contentDisposition &&
            contentDisposition.indexOf("attachment") !== -1
          ) {
            const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
              contentDisposition
            );
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, ""); // 去掉引号
            }
          }

          // 假设返回的是二进制流
          const blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename); // 使用提取的文件名
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url); // 释放内存
        })
        .catch((error) => {
          $q.notify({
            message: "下载失败，请重试",
            color: "negative",
          });
          console.error("下载对局demo失败:", error);
        });
    };

    const getStatusClass = (status) => {
      switch (status) {
        case 1:
          return "text-info";
        case 2:
          return "text-positive";
        case 3:
          return "text-negative";
        default:
          return "";
      }
    };

    const formatStatus = (status) => {
      switch (status) {
        case 1:
          return "待处理";
        case 2:
          return "已通过";
        case 3:
          return "已驳回";
        default:
          return "";
      }
    };

    onMounted(() => {
      fetchReports({
        pagination: serverTablePagination.value,
      });
    });

    return {
      reportList,
      selectedStatus,
      statusOptions,
      columns,
      dialog,
      auditOpinion,
      currentReportId,
      serverTablePagination,
      fetchReports,
      openAuditDialog,
      submitAudit,
      closeDialog,
      getStatusClass,
      formatStatus,
      downloadGameDemo,
    };
  },
};
</script>

<style scoped>
</style>
