import request from '@/router/axios'

export function queryServerList(pageNo, pageSize) {
    return request({
        url: '/servers/list',
        method: 'get',
        params: {"pageNo": pageNo, "pageSize": pageSize}
    })
}

export function updateServer(server) {
    return  request({
        url: `/servers/${server.id}`,
        method: 'put',
        data: server
    })
}

export function addServer(server) {
    return request({
        url: '/servers',
        method: 'post',
        data: server
    })
}

export function deleteServer(ids) {
    return request({
        url: '/servers',
        method: 'delete',
        data: ids
    })
}