<template>
  <div>
    <div class="q-ma-md">
      <q-card>
        <q-table :rows="gameHistory" :columns="columns" row-key="game_id" v-model:pagination="pagination"
          @request="fetchHistories" :filter="gameId">
          <template v-slot:body-cell-state="props">
            <q-td :props="props">
              <q-badge :color="getFormatClass(props.row.state)">{{ formatState(props.row.state) }}</q-badge>
            </q-td>
          </template>
          <template v-slot:top-right>
            <q-input dense debounce="300" v-model="gameId" placeholder="对局ID">
              <template v-slot:append>
                <q-icon name="search" />
              </template>
            </q-input>
          </template>

          <template v-slot:body-cell-action="props">
            <q-td :props="props">
              <q-btn flat @click="detail(props.row)" color="primary">查看详情</q-btn>
            </q-td>
          </template>
        </q-table>
      </q-card>
    </div>
  </div>
</template>

<script>
import { allGameHistories } from "@/api/player";
export default {
  name: "GameHistory",
  data() {
    return {
      gameHistory: [],
      states: [
        { value: -1, label: '对局结束' },
        { value: -2, label: '对局有玩家未连接' },
        { value: -3, label: '玩家退出' },
        { value: -4, label: '玩家退出' },
        { value: -5, label: '玩家被ban' },
        { value: -6, label: '队伍投降' },
        { value: -9, label: '与服务器断连' },
      ],
      columns: [
        { name: 'game_id', label: '对局ID', align: 'left', field: 'game_id' },
        { name: 'create_time', label: '比赛时间', align: 'left', field: 'create_time', required: true },
        { name: 'map_name', label: '地图', align: 'left', field: 'map_name' },
        { name: 'state', label: '状态', align: 'left', field: 'state' },
        { name: 'scores', label: '分数', align: 'left', field: row => row.survivor_score + ' / ' + row.infected_score },
        { name: 'action', label: '操作', align: 'center' }
      ],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        rowsNumber: 0
      },
      gameId: '',
    }
  },
  mounted() {
    this.fetchHistories({
      pagination: this.pagination,
      filter: undefined
    });

  },
  methods: {
    fetchHistories(props) {
      const { page, rowsPerPage } = props.pagination;
      const gameId = props.filter;
      
      return allGameHistories(gameId, page, rowsPerPage).then((res) => {
        this.pagination.rowsNumber = res.data.extend.total; // 设置总页数
        this.gameHistory = res.data.extend.records
        this.pagination.page = page;
        this.pagination.rowsPerPage = rowsPerPage;
      });
    },
    detail(game) {
      this.$router.push({
        path: '/detail/' + game.game_id,
      });
    },
    formatState(stateValue) {
      const state = this.states.find(item => item.value === stateValue);
      return state ? state.label : '未知状态'; // 返回对应的 label 或 '未知状态'
    },
    getFormatClass(stateValue) {
      return (stateValue === -1 || stateValue === -4) ? 'green' : 'red';
    }
  }
}
</script>

<style scoped>
/* 任何额外的样式 */
</style>
