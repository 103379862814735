import request from '@/router/axios'

export function queryReportList(status, pageNo, pageSize) {
    return request({
        url: '/report/list',
        method: 'get',
        params: {"status":status, "pageNo": pageNo, "pageSize": pageSize}
    })
}

export function auditReport(reportId, agree, auditOpinion) {
    return  request({
        url: `/report/audit/${reportId}`,
        method: 'put',
        data: {"agree":agree, "auditOpinion": auditOpinion}
    })
}

export function submitReport(data) {
    return request({
        url: '/report/submit',
        method: 'post',
        data: data
    })
}

export function downloadGameDemoFile(gameId) {
    return request({
        url: '/guest/download/'+ gameId,
        method: 'get',
        responseType: 'blob'
    })

}