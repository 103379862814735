<template>
  <div>
    <div class="q-ma-md">
      <q-splitter v-model="splitterModel">

        <template v-slot:before>
          <q-tabs v-model="tab" vertical inline-label class="text-teal">
            <q-tab name="serverListTab" icon="none" label="服务器列表" />
            <q-tab name="reportList" icon="none" label="举报列表" />
            <q-tab name="gameHistory" icon="none" label="对局记录" />
            <q-tab name="banManagement" icon="none" label="封禁管理" />
          </q-tabs>
        </template>

        <template v-slot:after>
          <q-tab-panels v-model="tab" animated swipeable transition-prev="jump-up" transition-next="jump-up">
            <q-tab-panel name="serverListTab">
              <div class="q-pa-md">
                <ServerList />
              </div>
            </q-tab-panel>

            <q-tab-panel name="reportList">
              <div class="q-pa-md">
                <ReportList />
              </div>
            </q-tab-panel>

            <q-tab-panel name="gameHistory">
              <div class="q-pa-md">
                <GameHistory />
              </div>
            </q-tab-panel>
            <q-tab-panel name="banManagement">
              <div class="q-pa-md">
                <BanManagement />
              </div>
            </q-tab-panel>
          </q-tab-panels>
        </template>
      </q-splitter>
      
    </div>
  </div>
</template>

<script>
import { ref,  } from 'vue'
import ServerList from '../../components/ServerList.vue';
import ReportList from '../../components/ReportList.vue';
import GameHistory from '../../components/GameHistory.vue';
import BanManagement from '../../components/BanManagement.vue';
export default {
  components: {
    ServerList,
    ReportList,
    GameHistory,
    BanManagement
  },
  setup() {
    return {
      tab: ref('serverListTab'),
      splitterModel: ref(20),
      
    }
  }
}
</script>